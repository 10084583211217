@import './normalize.css';

:root {
    --color-primary: #2584ff;
    --color-secondary: #00d9ff;
    --color-accent: #ff3400;
    --color-headings: #1b0760;
    --color-body:#918ca4;
    --color-nav: #fff;
    --color-body-darker:#5c5577;
    --color-box-background: #ccc;
    --color-box-verylightbackground: #eee;
    --color-box-lightbackground: #cebd29;
    --color-border: #cec;
    --color-box-coral: #e29014;
    --color-black: #000;
    --border-radius: 30px;
}

*, *::after, *::before {
    box-sizing: border-box;
}

@font-face {
    font-family: 'amalficoast';
    src: url('/fonts/amalfi/amalfi_coast-webfont.woff2') format('woff2'),
         url('/fonts/amalfi/amalfi_coast-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

/* Typography */
html {
    font-size: 62.5%;
    scroll-behavior: smooth;
}

body {
    font-family: Inter, Arial, Helvetica, sans-serif;
    font-size: 2.4rem;
    line-height: 1.5;
    background: white;
}

h1, h2, h3 {
    color: var(--color-headings);
    margin-bottom: 1rem;
    line-height: 1.1;
}

h1 {
    font-size: 7rem;
}

h2 {
    font-size: 4rem;
}

h3 {
    font-size: 3rem;
    font-weight: 500;
}

p {
    margin-top: 0;
}

@media screen and (min-width: 1024px) {
    body {
        font-size: 1.8rem;
    }

    h1 {
        font-size: 8rem;
    }

    h2 {
        font-size: 4rem;
    }

    h3 {
        font-size: 2.4rem;
    }
}

/* TODO: Links */

/* TODO: Badges */

/* Sizing */
.align__horizontal{
    float: left;
    margin-top: 0px;
}

/* List */
.list {
    list-style: none;
    padding-left: 0;
    text-transform: uppercase;
    margin: 0;
}

.list--inline .list__item {
    display: inline-block;
    margin-right: 2rem;
}

.list__body > p {
     line-height: 0;
}

/* Icons */
.icon {
    width: 60px;
    height: 60px;
}

.icon--large {
    width: 100px;
    height: 100px;
}

.icon--small {
    width: 40px;
    height: 40px;
}

.icon--primary {
    fill: var(--color-body-darker);
}

.icon--black {
    color: black;
}

.icon--white {
    fill: var(--color-nav);
}

.icon-container {

}

/* Link */
a {
    text-decoration: none;
}

/* Button */
.btn {
    border: 0;
    cursor: pointer;
    font-size: 1.5rem;
    font-weight: 550;
    margin: 1rem 0;
    outline: 0;
    padding: 1.5rem 3.5rem;
    text-align: center;
    text-transform: uppercase;
    white-space: nowrap;
    color: #fff;
    letter-spacing: 0.5rem;
}

.btn--primary {
    background: var(--color-body-darker);
}

.btn--primary .btn--transparent{
    opacity: 0.5;
}

.btn--primary:hover {
    background: #918ca4;
}

.btn--outline {
    background: var(--color-body-darker);
    border: 2px solid #fff;
}

.btn--transparent {
    background: var(--color-nav);
    border: 4px solid var(--color-black);
}

.btn--outline__darker {
    background: var(--color-black);
    border: 2px solid #fff;
}

.btn--outline:hover {
    background: #fff;
    border: 2px solid #000;
    color: #000;
}

.btn--transparent:hover {
    background: var(--color-box-coral);
    border: 4px solid var(--color-black);
}

.box--pascal {
    text-transform:capitalize;
}

/* TODO: Input */

/* Image */
.image--cropped  {
    /* display: block;
    width: 100%;
    margin-left: auto;
    margin-right: auto; */
}

.image--cropped > img {
    width: 20rem;
    height: 20rem;
    border-radius: 100%;
    display: block;
    margin-left: auto;
    margin-right: auto;
}

/* Plan */
.plan {
    padding: 2rem 2rem;
    display: inline-block;
    justify-content: center;
    transition: transform .2s ease-out
}

.plan--name {
    text-transform: uppercase;
    text-align: center;
    padding: 2rem 2rem;
}

.plan--title{
    text-transform: capitalize;
    margin-top: 0;
    margin-bottom: 0;
    text-align: center;
}

.plan:hover {
    transform: scale(1.05);
}

/* TODO: Media */

/* TODO: Quote */

/* Grids */
.grid {
    display: grid;
}

.grid__background__transparent {
    background: blue;
}

@media screen and (min-width: 768px) {
    .grid--1x2 {
        grid-template-columns: repeat(2, 1fr)
    }
}

@media screen and (min-width: 1024px) {
    .grid--1x3 {
        grid-template-columns: repeat(3, 1fr)
    }
}

@media screen and (min-width: 1024px) {
    .grid--1x4 {
        grid-template-columns: repeat(4, 1fr)
    }
}

/* TODO: Testimonial */

/* TODO: Callout */

/* Collapsible */
.collapsible {
    border-bottom: 1px solid #fff;
    padding: 2rem 0rem;
    font-family: Georgia, 'Times New Roman', Times, serif;
}

.collapsible__header {
    display: flex;
    justify-content: space-between;
}

.collapsible_heading {
    margin-top: 0;
    font-size: 2.2rem;
}

/* .collapsible__toggler {
    transform: rotate(-90deg);
    transition: transform 0.3s;
} */

.collapsible__content {
    max-height: 0;
    overflow: hidden;
    opacity: 0;
    font-size: 1.8rem;
    transition: all 0.3s;
}

/* .collapsible--expanded .collapsible__toggler {
    transform: rotate(0);
} */

.collapsible--expanded .collapsible__content {
    /* display: block; */
    max-height: 100vh;
    opacity: 1;
}

.nav_obscure{
    visibility: hidden;
}

@media screen and (min-width: 768px){
    .collapsible_heading {
        margin-top: 0;
        font-size: 3rem;
    }

    .collapsible__content {
        max-height: 0;
        overflow: hidden;
        opacity: 0;
        transition: all 0.3s;
    }

    .nav_obscure{
        visibility: visible; 
    }
}

/* Blocks */
.block {
    --padding-vertical: 6rem;
    padding: var(--padding-vertical) 2rem;
}

.block__header {
    text-align: center;
    margin-bottom: 4rem;
}

.block__heading {
    margin-top: 0;
}

.block--dark {
    background: #000;
    color: #7b858b;
}

.block--dark h1,
.block--dark h2,
.block--dark h3 {
    color: #fff;
}

.block--skewed-right {
    padding-bottom: calc(var(--padding-vertical) + 4rem);
    clip-path: polygon(0% 0%, 100% 0%, 100% 100%, 0% 90%);
}

.block--skewed-left {
    padding-bottom: calc(var(--padding-vertical) + 4rem);
    clip-path: polygon(0% 0%, 100% 0%, 100% 90%, 0% 100%);
}

.container {
    max-width: 1140px;
    margin: 0 auto;
}

/* Navigation Bar */
.nav {
    /* background: var(--color-box-lightbackground); */
    background: var(--color-box-coral);
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    padding: 0 1rem;
    align-items: center;
    margin: 0;
}

.nav__list{
    width: 100%;
    max-height: 0;
    font-weight: bold;
    letter-spacing: 0.2rem;
    /* font-size: 1rem; */
}

.nav__item {
    font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
    padding: 0.5rem 2rem;
    font-size: 1.5rem;
    border-bottom: 1px solid var(--color-body-darker);
    margin-top: 1rem;
}

.nav_search_container {
    display: none;
}

.nav__rsvp {
    font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
    font-weight: bold;
    padding: 0.5rem 2rem;
    font-size: 2rem;
    margin-top: 0.2rem;
    margin-right: 1rem;
    margin-bottom: 1rem;
    background-color: var(--color-nav);
    border: 1px solid var(--color-body-darker);
}

.nav__rsvp > a {
    color: var(--color-black);
    transition: color 0.3s;
}

.nav__rsvp > a:hover{
    color: var(--color-box-lightbackground);
}

.collapsible--expanded .nav__item__majorcontrols {
    display: none;
}

.nav__item--emphasized {
    font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
    padding: 0.5rem 2rem;
    font-size: 1.5rem;
    border-bottom: 1px solid var(--color-body-darker);
    margin-top: 1rem;
    font-size: 2rem;
    margin-top: 0.5rem;
    margin-bottom: 1rem;
    letter-spacing: 0rem;
    background-color: var(--color-nav);
}

.nav__item--emphasized > a {
    color: var(--color-black);
    transition: color 0.3s;
}

.nav__item--emphasized > a:hover{
    color: var(--color-box-lightbackground);
}

.nav__majorcontrols {
    font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
    font-weight: bold;
    padding: 0.5rem 1rem;
    font-size: 2rem;
    margin-top: 0.2rem;
    margin-right: 1rem;
    margin-bottom: 1rem;
    background-color: var(--color-nav);
    border: 1px solid var(--color-body-darker);
}

@media screen and (min-width: 770px) {
    .nav__majorcontrols {
        display: none;
    }
}

.nav__item__majorcontrols {
    font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
    padding: 0.5rem 0.5rem;
    font-size: 1.5rem;
    border-bottom: 1px solid var(--color-body-darker);
    margin-top: 1rem;
    font-size: 2rem;
    margin-top: 0.5rem;
    margin-bottom: 1rem;
    letter-spacing: 0rem;
    background-color: var(--color-nav);
}

.nav__item__majorcontrols--container {
    background-color: transparent;
    /* transition: color 0.3s; */
}

/* .nav__item--emphasized > .nav__item__majorcontrols:hover{
    color: var(--color-box-lightbackground);
} */

.nav__item > a {
    color: var(--color-black);
    transition: color 0.3s;
}

.nav__item > a:hover{
    color: #fff;
}

.nav__toggler {
    opacity: .5;
    transition: box-shadow .15s;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
}

.nav.collapsible--expanded .nav__toggler {
    opacity: 1;
    box-shadow: 0 0 0 3px #666;
    border-radius: 5px;
}

.nav__container {
    border-radius: 100%;
    /* background: var(--color-body-darker); */
    width: 60px;
    height: 60px;
    border-radius: 100%;
    display: inline-flex;
    justify-content: center;
    align-items: center;   
    margin: 0.5rem;
}

.nav__container > p {
    font-family: 'amalficoast', 'Send Flowers', cursive;
    color: var(--color-headings);
    margin-top: 1rem;
}

.nav__brand {
    font-family: 'amalficoast', 'Send Flowers', cursive;
    font-size: 1.5rem;
    font-weight: bold;
    /* margin: 1rem; */
}

.nav__item--link{
    display: none;
}

@media screen and (min-width: 770px) {
    .nav__toggler {
        display: none;
    }

    .nav__item--link{
        cursor: pointer;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .nav__rsvp {
        display: none;
    }

    .nav__list {
        width: auto;
        display: flex;
        font-size: 1.2rem;
        max-height: 100%;
        opacity: 1;
    }

    .nav__item {
        border: 0;
    }
}

/* Hero */
.hero {
    position: relative;
}

.hero > img {
    width: 100%;
    /* height: auto; */
}

.hero--image__downsize {
    display: flex;
    width: 50%;
}

.hero--content {
    position: absolute;
    top: 0;
    width: 100%;
    text-align: center;
    font-size: 120px;
}

.hero__header {
    font-family: 'amalficoast', 'Send Flowers', cursive;
    font-size: 50px;
    color: #fff;
    margin-top: 2rem;
    margin-bottom: 375px;
    text-shadow: 2px 2px #ff0000;
}

.hero__header--sub {
    margin-top: 6rem;
}

.hero__footer {
    justify-content: center;
    line-height: 0.5rem;
}

.hero__footer > p {
    color: white;
    font-size: 20px;
    margin-bottom: 0;
}

.hero__button {
    margin-top: 0;
}

@media screen and (min-width: 768px) {
    .hero__header {
        font-size: 80px;
        margin-bottom: 1050px;
        margin-top: 2rem;
    }

    .hero__header--sub {
        margin-top: 4rem;
    }

    .hero__footer {
        line-height: 2rem;
    }

    .hero__footer > p {
        font-size: 40px;
        margin-bottom: 0;
    }
    
    .hero__button {
        margin-top: 0;
    }
}

@media screen and (min-width: 1024px) {
    .hero__header {
        font-size: 110px;
        margin-bottom: 1580px;
        margin-top: 6rem;
    }

    .hero__header--sub {
        margin-top: 6rem;
    }

    .hero__footer {
        line-height: 2rem;
    }

    .hero__footer > p {
        font-size: 40px;
        margin-bottom: 0;
    }
    
    .hero__button {
        margin-top: 0;
    }
}

/* Detail Box */
.box {
    background: #fff;
    max-width: 32rem;
    padding: 2rem 1rem;
    opacity: 0.8;
}

.box--transparent {
    background: transparent;
}

.box--heavy__opaque {
    opacity: 0.6;
}

.box__icon {
    width: 100%;
    margin-top: 3rem;
    margin-bottom: 1rem;
    display: inline-block;
}

.box__button {
    display: block;
    margin: 2rem auto;
    font-size: 1rem;
}

.box__separator{
    max-width: 3rem;
}

/* Box Containers */
.grid__container {
    /* background: var(--color-box-lightbackground); */
    background: var(--color-box-coral);
    margin: 2rem;
    min-height: 50rem;
}

.grid__header {
    font-family: 'amalficoast', 'Send Flowers', cursive;
    font-size: 40px;
    font-weight: bold;
    line-height: 0;
    padding: 5rem;
    align-items: center;
    color: var(--color-headings);
}

.grid__header > h2 {
    text-align: center;
    margin-top: 0;
    margin-bottom: 2rem;
    font-family: 'amalficoast', 'Send Flowers', cursive;
    color: var(--color-nav);
    margin-top: -2rem;
}

.grid__header > p {
    font-family: Georgia, 'Times New Roman', Times, serif;
    text-align: center;
    margin-top: 5rem;
    margin-bottom: 0rem;
    font-size: 1.6rem;
    /* letter-spacing: 0rem; */
    /* font-family: 'amalficoast', 'Send Flowers', cursive; */
}

.grid__body {
    margin: 1rem;
    justify-content: center;
}

.grid__body--items {
    margin-bottom: 1rem;
}

.grid--bg--white {
    background: white;
}

.grid--bg--image {
    /* background: url(/images/throne_opac.jpg); */
    background-color: white;
}

.grid__container--img {
    margin: 4rem;
}

.grid--bg--image__NTWAH {
    /* background-image: url(/images/Orange3.jpg); */
    background-size: 100%;
    background-repeat: no-repeat;
    /* width:100%; */
    height: auto;
    margin: 2rem;
    background-color: var(--color-black);
}

.grid--bg--image__NTWAH .grid__header{
    text-shadow: 2px 2px #ff0000;
} 

.grid--margin__NTWAH {
    padding-top: 35rem;
}

.grid--bg--image__GIFTS {
    /* background-image: url(/images/Gym2.jpg); */
    background-size: 100%;
    background-repeat: no-repeat;
    /* width:100%; */
    height: auto;
    margin: 2rem;
    background-color: var(--color-black);
}

.grid--bg--image__GIFTS .grid__header{
    text-shadow: 2px 2px #ff0000;
}

.grid--margin__Gifts {
    padding-top: 15rem;
}

/* @media screen and (min-width:1024px) {
    .grid--bg--image__NTWAH {
        
    }
} */

.grid--transparent {
    opacity: 20%;
}

.grid__image {
    position: relative;
    font-family: Georgia, 'Times New Roman', Times, serif;
    color: black;
    letter-spacing: 0.1rem;
}

.grid__image--img{
    display: block;
    height: auto;
    margin-left: auto;
    margin-right: auto;
    width: 80%;
    height: 45rem;
    opacity: 0.2;
    z-index: -1;
}

.grid--contentinfo {
    text-align: left;
    font-family: Georgia, 'Times New Roman', Times, serif;
    font-size: 1.5rem;
}

.grid--contentinfo--flush_center {
    text-align: center;
    /* font-family: 'amalficoast', 'Send Flowers', cursive; */
    font-size: 1.5rem;
    letter-spacing: 0rem;
}

.grid__overlay {
    /* position: absolute; */
    /* position: absolute; */
    align-items: center;
    max-width: 100rem;
    /* top: 4rem; */
    margin-top: 4rem;
    margin-bottom: 2rem;
    margin-left: 2rem;
    margin-right: 2rem;
    align-content: center;
    /* left: 1rem; */
    font-weight: bolder;
    text-align: center;
    min-height: 10;
}

.grid__overlay .grid--contentinfo--flush_center{
    margin-top: 4rem;
    margin-bottom: 8rem;
}

.grid__overlay > p {
    font-family: 'amalficoast', 'Send Flowers', cursive;
    font-size: 3rem;
}

.grid__body--items > p {
    text-align: center;
    color: var(--color-nav);
    font-size: 2rem;
    font-weight: bold;
    font-family: Georgia, 'Times New Roman', Times, serif;
}

.grid--outline {
    border: 2px solid #3b3a3a;
}

.grid__content{
    display: none;
    text-align: center;
    font-size: 1.5rem;
    font-family: Georgia, 'Times New Roman', Times, serif;
}

.grid__content > p {
    color: var(--color-nav);
    font-size: 2rem;
    font-weight: bold;
}

.grid__collapsible .grid__content {
    display: block;
}

.grid__collapsible {
    transform: scale(1.2);
    opacity: 1;
    border: 2px solid white;
}

.grid__content--opaque {
    /* color: var(--color-primary); */
}

.grid__container .grid__collapsible {
    margin-bottom: 10rem;
    margin-top: 10rem;
}

.grid__footer{
    margin-top: 5rem;
    font-family: 'amalficoast', 'Send Flowers', cursive;
    line-height: 1rem;
    font-size: 1.5rem;
}

.grid__list {
    font-size: 1.8rem;
    color: var(--color-nav);
}

@media screen and (min-width: 768px) {
    .grid__image--img{
        display: block;
        height: auto;
        margin-left: auto;
        margin-right: auto;
        width: 35%;
        height: 50rem;
    }
    
    .grid__overlay {
        /* position: absolute; */
        align-items: center;
        max-width: 60rem;
        /* top: 6.5rem; */
        margin-top: 6.5rem;
        margin-bottom: 2rem;
        margin-left: 2rem;
        margin-right: 2rem;
        /* left: 15%; */
        text-align: center;
        z-index: 1;
    }

    .grid__overlay > p {
        font-family: 'amalficoast', 'Send Flowers', cursive;
        font-size: 4rem;
    }

    .grid__header > h2 {
        margin-top: -2rem;
    }

    /* .grid--margin__Gifts {
        padding-top: 10rem;
    } */

    /* .grid--margin__NTWAH {
        padding-top: 100rem;
    } */
}

@media screen and (min-width: 1024px) {
    .grid__body {
        display: flex;
        align-content: space-between;
        width: auto;
    }
    
    .grid__body--items {
        margin: 1rem;
    }

    .grid__container .grid__collapsible {
        margin-left: 5rem;
        margin-right: 5rem;
        font-size: 1rem;
    }

    .grid__image--img{
        display: block;
        height: auto;
        margin-left: auto;
        margin-right: auto;
        width: 35%;
        height: 50rem;
    }
    
    .grid__overlay {
        /* position: absolute; */
        align-items: center;
        max-width: 160rem;
        /* top: 2.5rem; */
        margin-top: 2.5rem;
        margin-bottom: 2rem;
        margin-left: 2rem;
        margin-right: 2rem;
        /* left: 25%; */
        text-align: center;
        z-index: 1;
    }

    .grid--contentinfo {
        text-align: left;
        font-family: Georgia, 'Times New Roman', Times, serif;
        font-size: 2rem;
    }

    .grid--contentinfo--flush_center {
        text-align: center;
        font-family: Georgia, 'Times New Roman', Times, serif;
        font-size: 1.5rem;
    }

    .grid__overlay > p {
        font-family: 'amalficoast', 'Send Flowers', cursive;
        font-size: 3.5rem;
    }

    .grid__header > p {
        text-align: center;
        margin-top: 5rem;
        margin-bottom: 2rem;
        font-size: 4rem;
        letter-spacing: 0.5rem;
        /* font-family: 'amalficoast', 'Send Flowers', cursive; */
    }

    .grid__footer{
        margin-top: 5rem;
        font-family: 'amalficoast', 'Send Flowers', cursive;
        line-height: 1rem;
        font-size: 2rem;
        font-weight: bold;
    }

    .grid__header > h2 {
        margin-top: -2rem;
    }

    .grid--margin__Gifts {
        padding-top: 10rem;
    }

    .grid--margin__NTWAH {
        padding-top: 2rem;
    }
}

@media screen and (min-width: 1440px) {
    .grid__image--img{
        display: block;
        height: auto;
        margin-left: auto;
        margin-right: auto;
        width: 35%;
        height: 55rem;
    }

    .grid--contentinfo {
        text-align: left;
        font-family: Georgia, 'Times New Roman', Times, serif;
        font-size: 2.1rem;
    }

    .grid--contentinfo--flush_center {
        text-align: center;
        font-family: Georgia, 'Times New Roman', Times, serif;
        font-size: 1.5rem;
    }
    
    .grid__overlay {
        /* position: absolute; */
        align-items: center;
        max-width: 160rem;
        /* top: 2rem; */
        margin-top: 2rem;
        margin-bottom: 2rem;
        margin-left: 2rem;
        margin-right: 2rem;
        /* left: 30%; */
        font-size: 2.2rem;
        text-align: center;
    }

    .grid__overlay > p {
        font-family: 'amalficoast', 'Send Flowers', cursive;
        font-size: 5rem;
    }

    .grid__footer{
        margin-top: 5rem;
        font-family: 'amalficoast', 'Send Flowers', cursive;
        line-height: 1rem;
        font-size: 2.1rem;
        font-weight: bold;
    }

    .grid__header > h2 {
        margin-top: -2rem;
    }
}

/* Gallery */
.gallery {
    padding: 2rem;
    padding-bottom: 4rem;
    display: inline-block;
    align-items: center;
    justify-content: center;
    width: 100%;
}

.gallery > h2 {
    text-align: center;
    margin-top: 0;
    margin-bottom: 2rem;
    font-family: 'amalficoast', 'Send Flowers', cursive;
}

.gallery__image {
    max-width: 100rem;
    height: 300px;
}

.gallery__image:hover {
    transform: scale(1.1);
    border: 1px solid #ccc;
}

.image__container {
    display: block;
    margin-left: 10rem;
    margin-right: 10rem;
}

@media screen and (min-width: 768px) {
    .gallery__image {
        max-width: 100em;
        height: 300px;
    }

    .image__container {
        display: block;
        margin-left: 10rem;
        margin-right: 10rem;
    }
}

@media screen and (min-width: 1024px) {
    .gallery__image {
        max-width: 450px;
        height: 300px;
    }

    .image__container {
        display: block;
        margin-left: 10rem;
        margin-right: 10rem;
    }
}

/* Sections - FAQ */
.faq {
    padding: 5rem;
    background: var(--color-box-lightbackground);
    margin: 2.5rem;
}

.faq > h2 {
    text-align: center;
    margin-top: 0;
    margin-bottom: 2rem;
    font-family: 'amalficoast', 'Send Flowers', cursive;
}

/* Article */
.article {
    /* background: var(--color-box-lightbackground); */
    background: var(--color-box-coral);
    margin: 2rem;
    padding: 2rem;
}

.article--header{
    text-align: center;
    font-family: 'amalficoast', 'Send Flowers', cursive;
    font-size: 3rem;
    font-weight: bold;
    line-height: 0;
    padding: 3rem;
    color: var(--color-headings);
}

.article--body {
    font-family: Georgia, 'Times New Roman', Times, serif;
    text-align: center;
    font-size: 1.5rem;
    /* letter-spacing: 0.1rem; */
}

.article--title {
    font-family: 'amalficoast', 'Send Flowers', cursive;
    font-size: 2rem;
    padding-top: 2rem;
}

.article__button {
   text-align: right;
}

.article__button .btn--primary {
    background: transparent;
}

.aticle--context {

}

.article--context__summary {

}

.article--context__body {
    /* display: none; */
    max-height: 0;
    overflow: hidden;
    opacity: 0;
    font-size: 1.8rem;
    transition: all 0.3s;
}

.article__expanded .article--context__body {
    /* display: block; */
    max-height: 500vh;
    opacity: 1;
}

.article__expanded .article__button {
    display: none;
}

.article__button > a:hover {
    color: #fff;
}

/* Fonts */
.facustom {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 3rem;
}

.facustom--item {
    color: var(--color-black);
    transform: scale(1.0);
    /* padding: 0 1.2rem 0 1.2rem; */
    padding: 0 1.5rem 0 1.5rem;
    min-width: 6rem;
}

.facustom--item--secondary {
    padding: 0 0.5rem 0 0.5rem;
}

.fa_link {
    color: var(--color-black);
}

.fa_close {
    color: var(--color-accent);
}

@media screen and (min-width:1024px) {
    .facustom--item {
        transform: scale(1.0);
        padding: 0 1.5rem 0 1.5rem;
    }

    .article--header{
        text-align: center;
        font-family: 'amalficoast', 'Send Flowers', cursive;
        font-size: 45px;
        font-weight: bold;
        line-height: 0;
        padding: 3rem;
        color: var(--color-headings);
    }
}

/* Signatory */
.signatory{
    font-family: 'amalficoast', 'Send Flowers', cursive;
    text-align: center;
    font-weight: bold;
    font-size: 2.2rem;
    margin-top: 8rem;
    margin-bottom: 4rem;
}

/* Form */
.form__image{
    display: block;
    margin-left: auto;
    margin-right: auto;
    text-align: center;
}

.form__image > h2 {
    font-size: 3rem;
    align-items: center;
}

.form__container {
    margin: 4rem;
    /* margin-left: auto;
    margin-right: auto; */
    padding: 2rem;
    width: auto;
    border: 1px solid var(--color-black);
}

.form__content {
    font-family: Georgia, 'Times New Roman', Times, serif;
    margin: auto;
    text-align: left;
    font-size: 2rem;
}

.form__block {
    margin-top: 2rem;
    margin-bottom: 2rem;
}

.form__block > p {
    margin-bottom: 1rem;
}

.form__block > input {
    width: 100%;
    height: 5rem;
    background-color: var(--color-box-verylightbackground);
    border: 1px solid var(--color-box-lightbackground);
}

.form__block > select {
    width: 100%;
    height: 5rem;
    background-color: var(--color-box-verylightbackground);
    border: 1px solid var(--color-box-lightbackground);
}

.form__content > button {
    align-items: center;
    font-weight: bold;
    padding: 2rem;
}

.form__grid {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    column-gap: 1rem;
}

.form__block--label > p {
    margin-bottom: -1rem;
}

.form__block > textarea{
    width: 100%;
    height: 10rem;
    text-align: left;
    background-color: var(--color-box-verylightbackground);
    border: 1px solid var(--color-box-lightbackground);
}

.confirm {
    display: none;
}

.formDialogbody {
    font-family: sans-serif
}

/* Use This*/
.form__dialog {
    margin-top: 4rem;
    margin-bottom: 4rem;
    margin-left: 12rem;
    margin-right: 12rem;
    padding: 2rem;
    width: auto;
    border: 1px solid var(--color-black);
    visibility: collapse;
    height: 0rem;
}

.dialog {
    font-family: Georgia, 'Times New Roman', Times, serif;
    margin: auto;
    text-align: left;
    font-size: 2rem;
}

.dialog header{
    text-align: right;
    margin-top: -1rem;
    margin-bottom: 2rem;
}

.dialog .icon__dialog {
    color: var(--color-box-coral)
}

.dialog .icon__dialog:hover {
    color: var(--color-accent)
}

.form__dialog__header{
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    justify-content: space-between;
}

.dialog h3{
    color: var(--color-box-coral)
}

/* Extract From This*/
.dialog-ovelay .dialog header {
    padding: 10px 8px;
    background-color: #f6f7f9;
    border-bottom: 1px solid #e5e5e5;
}

.dialog-ovelay .dialog header h3 {
    font-size: 14px;
    margin: 0;
    color: #555;
    display: inline-block
}

.dialog-ovelay .dialog header .fa-close {
    float: right;
    color: #c4c5c7;
    cursor: pointer;
    transition: all .5s ease;
    padding: 0 2px;
    border-radius: 1px
}

.dialog-ovelay .dialog header .fa-close:hover {
    color: #b9b9b9
}

.dialog-ovelay .dialog header .fa-close:active {
    box-shadow: 0 0 5px #673AB7;
    color: #a2a2a2
}

.dialog-ovelay .dialog .dialog-msg {
    padding: 12px 10px
}

.dialog-ovelay .dialog .dialog-msg p {
    margin: 0;
    font-size: 15px;
    color: #333
}

.dialog-ovelay .dialog footer {
    border-top: 1px solid #e5e5e5;
    padding: 8px 10px
}

.dialog-ovelay .dialog footer .controls {
    direction: rtl
}

.dialog-ovelay .dialog footer .controls .button {
    padding: 5px 15px;
    border-radius: 5rem;
}

/* .button {
    cursor: pointer
} */

.button-default {
    background-color: rgb(248, 248, 248);
    border: 1px solid rgba(204, 204, 204, 0.5);
    color: #5D5D5D;
    align-items: center;
    font-weight: bold;
    padding: 2rem;
    
}

.button-danger {
    background-color: #f44336;
    border: 1px solid #d32f2f;
    color: #f5f5f5
}

.link {
    padding: 5px 10px;
    cursor: pointer
}

/* QR Code block */
.qrblock{
    display: block;
    margin-left: auto;
    margin-right: auto;
    text-align: center;
}

.qrblock__body{
    margin-top: 10rem;
    margin-bottom: 10rem;
}

.qrblock > h2{
    text-align: center;
    font-size: 3rem;
    font-family: Georgia, 'Times New Roman', Times, serif;
}

.qrblock__body__item {
    padding: 3rem;
    text-align: center;
    font-size: 2.5rem;
    font-family: Georgia, 'Times New Roman', Times, serif;
}

/* Main Page */
.backdrop{
    -webkit-filter: blur(5px);
    -moz-filter: blur(5px);
    -o-filter: blur(5px);
    -ms-filter: blur(5px);
    filter: blur(5px);
    pointer-events: none;
}

/* Search */
.search{
    text-align: center;
    margin: 0.5rem 0 0.5rem 0;
}

.search__control{
    border: 2px solid var(--color-body-darker);
    min-width: 30rem;
    height: 3.2rem;
    font-size: 15px;
    /* font-size: 2p */
}

.searchOverlay{
    display: none;
    width: auto;
    height: 10rem;
    padding-top: 2.5rem;
    background: var(--color-box-coral);
    opacity: 0.5;
    transition: all 0.3s;
    filter: alpha(opacity = 50); /* required for opacity to work in IE */
}

.searchOverlayContainer{
    margin-left: 6rem;
}

@media screen and (min-width:768px) {
    .searchOverlay{
        display: none;
    }
}

@media screen and (min-width:1024px) {
    .search__control{
        border: 2px solid var(--color-body-darker);
        min-width: 30rem;
        /* font-size: 2p */
    }

    .searchOverlay{
        display: none;
    }
}

/* Sliders */
.slideshow-container {
    max-width: 1000px;
    position: relative;
    margin: auto;
}

.slideshow--resize {
    width: 100%;
    height: 500px;
    object-fit: cover;
}

.myTransitions{
    display: none;
}

.prev, .next {
    cursor: pointer;
    position: absolute;
    top: 50%;
    width: auto;
    margin-top: -22px;
    padding: 16px;
    color: white;
    font-weight: bold;
    font-size: 18px;
    transition: 0.6s ease;
    border-radius: 0 3px 3px 0;
    user-select: none;
}

.next{
    right: 0;
    border-radius: 3px 0 0 3px;
}

/* .prev:hover, .next:hover {
    background-color: rgba(0, 0, 0, 0.8);
} */

.text {
    color: var(--color-box-coral);
    font-size: 15px;
    font-weight: bold;
    padding: 8px 12px;
    position: absolute;
    bottom: 8px;
    width: 100%;
    text-align: center;
}

.numText {
    color: var(--color-box-coral);
    font-size: 12px;
    font-weight: bold;
    padding: 8px 12px;
    position: absolute;
    top: 0;
}

.dot-container {
    text-align: center;
}

.dot {
    cursor: none;
    height: 15px;
    width: 15px;
    margin: 0 2px;
    background-color: #bbb;
    border-radius: 50%;
    display: inline-block;
    transition: background-color 0.6s ease;
}

.active{
    background-color: #717171;
}

/* Animations */
.fade {
    animation-name: fade;
    animation-duration: 1.5s;
}

@keyframes fade {
    from {opacity: .4}
    to {opacity: 1}
}

/* Info */
.info_container{
    margin: 4rem 2rem;
}

.info_header{
    text-align: center;
    font-size: 25px;
}

.info_body{
    font-size: 15px;
}

.info_list_item{
    display: flex;
}

.info_list_item > div:nth-child(1){
    background-color: var(--color-box-coral);
    border-radius: 60%;
    text-align: center;
    align-content: center;
    margin-top: 10px;
    padding: 1rem;
}

.info_list_item > div:nth-child(2){
    line-height: 1px;
    margin-top: 3rem;
    margin-left: 2rem;
    align-content: center;
}

.info {
    padding: 1rem;
}

.info .grid__header {
    /* font-family: 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif; */
    /* width: 100%; */
    margin-right: auto;
    margin-left: auto;
    margin-bottom: 0;
    /* letter-spacing: 1rem; */
}

.info .grid__header--second {
    color: #043504;
}

.info .grid__body {
    margin: 0;
    align-items: center;
    justify-content: space-evenly;
    justify-items: center;
}

/* .info .grid__body--items {
    justify-content: center; */
    /* align-items: center;
} */

.info .grid__body--items > img {
    max-width: 80%;
    /* width: 50%; */
    margin-right: auto;
    margin-left: auto;
}

/* @media screen and (min-width: 768px){
    .info_list_item > div:nth-child(2) > p{
        font-size: 100px;
    }
} */

@media screen and (min-width: 1024px){
    .info_list_item{
        display: inline;
        text-align: center;
        padding: 0rem 4rem;
    }

    .info_list_item > div:nth-child(1){
        border-radius: 60%;
        margin: 0rem 9rem;
        padding: 2rem 2rem;
    }

    .info_list_item > div:nth-child(2) > p{
        font-size: 2rem;
    }

    .info_container{
        margin: 6rem;
    }

    .info_body{
        padding: 2rem 8rem;
        font-size: 25px;
        display: flex;
        justify-content: center;
    }
}